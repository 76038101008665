const CopyRightsText = () => {
    return (
        <p className="text-sm text-center py-4">
            © TUBES Revined is part of TUBES – Wines & Spirits by the glass –
            <a href="https://wineintubes.com" target="_blank" rel="noreferrer" className="text-[#7395a5] font-semibold hover:text-[#b6a97a]"> wineintubes.com</a>
            <br />
            REGISTERED & PATENT PENDING
            <br />
            Revined ™️
        </p>
    );
};

export default CopyRightsText;